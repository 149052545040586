import React from 'react'
import { navigate } from 'gatsby-link'
import Recaptcha from 'react-google-recaptcha'
import fetch from 'isomorphic-fetch'
import * as qs from 'query-string'
import styled from 'styled-components'

import SeoHeader from '../components/SeoHeader'
import FormItem from '../components/FormItem'

const StyledSubmitButton = styled.button`
  text-transform: uppercase;
  color: #000;
  font-family: 'FF DIN Pro Medium';
  margin: 0.5rem;
  margin-left: 0;
  padding: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border: 4px solid #000;
  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  :hover {
    background-position: left bottom;
    color: #FADB31;
  }
`

const StyledResetButton = styled.input`
  text-transform: uppercase;
  color: #000;
  font-family: 'FF DIN Pro Medium';
  margin: 0.5rem;
  padding: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border: 4px solid #E53E3E;
  background: linear-gradient(to right, #E53E3E 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  :hover {
    background-position: left bottom;
    color: #fff;
  }
`

const RECAPTCHA_KEY = '6LcoubkUAAAAAFAld15w5aevKtVust5LFinwdHe0'

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const formDataToSubmit = {
      'form-name': 'book-a-demo',
      ...this.state
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: qs.stringify(formDataToSubmit)
    })
      .then(() => navigate('/thanks'))
      .catch(error => window.alert(error))
  }

  render() {
    return (
      <>
        <SeoHeader
          title='Book A Demo'
        />
        <div className="w-full px-8 md:w-4/5 lg:w-2/3 xl:w-2/3 mx-auto">
          <h1 className="font-medium uppercase text-2xl">book a demo</h1>
          <h2 className="uppercase mb-4">see it to believe it</h2>
          <p className="mb-8">
            Kindly, fill the following form and one of our teams will contact
            you as soon as possible.
          </p>
          <form
            name="book-a-demo"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-recaptcha="true"
            netlify-honeypot="bot-field"
            className="py-4"
            onSubmit={this.handleSubmit}
          >
            <FormItem
              name="name"
              placeholder="Name"
              type="text"
              onChange={this.handleChange}
              required
              minLength="3"
            />
            <FormItem
              name="email"
              placeholder="Email"
              type="email"
              onChange={this.handleChange}
              required
            />
            <FormItem
              name="phone"
              placeholder="+971 or 05X phone"
              type="text"
              onChange={this.handleChange}
            />
            <FormItem
              name="company"
              placeholder="Company (optional)"
              type="text"
              onChange={this.handleChange}
            />
            <FormItem
              name="position"
              placeholder="Position (optional)"
              type="text"
              onChange={this.handleChange}
            />
            <p className="hidden">
              <label>
                Don’t fill this out if you&apos;re human: <input name="bot-field" />
              </label>
            </p>
            <FormItem
              name="message"
              placeholder="Your message"
              component="textarea"
              onChange={this.handleChange}
            />
            <Recaptcha
              ref={'recaptcha'}
              sitekey={RECAPTCHA_KEY}
              onChange={this.handleRecaptcha}
              className="my-2"
            />
            <StyledSubmitButton
              type="submit"
            >
              Submit
            </StyledSubmitButton>
            <StyledResetButton
              type="reset"
            />
          </form>
        </div>
      </>
    )
  }
}

// TODO FIX FORM VALIDATION
